import React, { Component } from 'react';
import { RaisedButton, MuiThemeProvider } from 'material-ui';
import { Fade } from '@material-ui/core';
import "./About.css";
import Ripples from 'react-ripples';
import { Link } from 'react-router-dom';

class About extends Component {
    render() {
        return(
            <div class="top">
                <MuiThemeProvider>
                    <Fade in={true} timeout={1500}>
                        <h4>About Compare Playlists</h4>
                    </Fade>
                </MuiThemeProvider>
                <Ripples during={200}>
                    <Link to="/">
                        <button className="btn btn-primary" label="Home" primary={true} width='.5vw'>Home</button>
                    </Link>
                </Ripples>
                <h2>What is it?</h2>
                <p class="intro">Compare Playlists is a tool that will create a Spotify playlist with the songs that you and your friends have in common.
                 The tool puts users in charge of the playlists they create by making the playlist on their own account. <br/></p>
                 
                <h2>How does it work?</h2>
                <p class="intro">The program works by accessing loading songs from your Spotify library and your friend's Spotify library, keeping track of which
                songs you have in common. It then makes a Spotify playlist on your behalf of the tracks that both profiles share.
                To get the most accurate comparisons, be sure to share the tool with your friends and have them compare with your profile as well.
                <br/>
                 </p>
                <hr></hr>
                <div class="contact-me">
                    <h2>Get In Touch</h2>
                    <p class="contact-message">Reach me at my email for any questions.</p>
                    <Ripples during={200}>
                        <a href={"mailto:compareplaylists@protonmail.com"}>
                            <button className="btn btn-primary" href="mailto:compareplaylists@protonmail.com" label="Contact me" primary={true} width='.5vw'>Contact me</button>
                        </a>
                    </Ripples>
                </div>
                <div class="bottom-container">
                    <p class="copyright">Created by Connell Hecht</p>
                </div>
            </div>
        )   
    }
}
export default About;