import React, { Component } from 'react';
import './App.css';
import Callback from './Callback.js'
import About from './About.js'
import { Route, BrowserRouter } from 'react-router-dom'
import Cookies from 'js-cookie';
import { RaisedButton } from 'material-ui';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Fade } from '@material-ui/core';
import UserInfo from './UserInfo';
import PlaylistForm from './PlaylistForm';
import Ripples from 'react-ripples';

var token = "";

class App extends Component {
  render() {
    return (
      <div /*className="backgroundImage"*/>
        <div className="App">
          <MuiThemeProvider>
              <Fade in={true} timeout={1500}>
                <div>
                  <h1 style={{ opacity: .9 }}>COMPARE PLAYLISTS</h1>
                  <h2 style={{"font-size": "2vw"}}>A simple tool for comparing Spotify libraries</h2>
                </div>
              </Fade>
              {RenderButtons()}
              <BrowserRouter>
                <Route path='/callback' component={Callback} />
              </BrowserRouter>
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}


class Compare extends Component {

  constructor(props) {
    super(props)
    this.state = {
      link: '',
      includeFollowing: false,
      show: true,
      displayName: '',
      profileImageURL: '',
      isVerfied: false,
    }
    this.checkResponse = this.checkResponse.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  checkResponse(response, link, includeSelf) {
    let code = response['status'];
    console.log(code);
    let token_cookie = Cookies.get('token');
    if (code === 401) {
      alert('Your session has expired. Please log out and try again.');
    } else if (code !== 200) {
      alert('A link that you provided was invalid. Please try again.');
    } else {
    const requestOptions = {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      otheruser: link,
      token: token_cookie,
      includeself: includeSelf.toString() },
    };
      console.log(requestOptions);
      fetch('https://3jrj0fyywi.execute-api.us-west-2.amazonaws.com/staging/compare', requestOptions).then(function(response) {
        if(!response.ok) {
          alert("Something went wrong! Please log out try again.");
      } else {
        alert('Your playlist is being created. Check your Spotify account in a few minutes to see your playlist!');
      }
    });
  }
  }

  handleSubmit(event) {
    event.preventDefault();

    var extracted = this.state.link;

    var start_index = extracted.indexOf('/user/');
    if (start_index !== -1) {
      start_index = start_index + '/user/'.length
    } else {
      start_index = extracted.indexOf(':user:');
      if (start_index !== -1) {
        start_index = start_index + ':user:'.length;
      } else {
        start_index = 0;
      }
    }

    var end_index = this.state.link.indexOf('?si=')

    if (end_index !== -1) {
      extracted = extracted.substring(start_index, end_index);
    } else {
      extracted = extracted.substring(start_index);
    }
    console.log(Cookies.get('token'));
    this.setState({link: extracted});
    console.log(this.state.link);

    const usernameOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + Cookies.get('token') }
    }
    
    fetch('https://api.spotify.com/v1/users/' + extracted, usernameOptions).then((responseData) => {this.checkResponse(responseData, this.state.link, this.state.includeFollowing); return responseData;});

  }

  render() {
    return(
      <div>
        <UserInfo token={Cookies.get('token')}></UserInfo>
        <br></br>
        <Ripples during={200}>
          <button onClick={ClearSession} className="btn btn-secondary" label="Log Out" primary={false}>
            Log Out
          </button>
        </Ripples>
          <h2>
            1. Sign in to Spotify (Done!)<br></br>
          </h2>
          <br/>
          <Ripples during={200}>
            <button label="About" className="btn btn-secondary" onClick={toAbout} primary={false}>
              About
            </button>
          </Ripples>
          <h2>
            2. Input a link to the profile of another Spotify user to compare profiles with<br></br>
          </h2>
          <PlaylistForm token={Cookies.get('token')}></PlaylistForm>
          <h2>
            3. Wait for the tool to create the playlist <br></br>
          </h2>
      </div>)
  }
}

function RenderButtons() {
  let current_callback_url_configuration = 'https://www.compareplaylists.com/callback';

  token = Cookies.get('token');
  if (token === undefined || token === 'undefined' || !token || token === 'null') {
    return (
      <div>
        <h2>
            1. Sign in with Spotify<br></br>
        </h2>
        <Ripples during={200}>
          <button onClick={SignIn} label="Sign in" type="button" className="btn btn-primary" style={{position: 'relative', display:'center', overflow:'hidden'}}>Sign In</button>
        </Ripples>
        <Ripples during={200}>
          <button onClick={toAbout} label="About" type="button" className="btn btn-secondary" style={{position: 'relative', display:'center', overflow:'hidden'}}>About</button>
        </Ripples>
        <h2>
          2. Paste the profile link of the user you want to compare with<br></br>
        </h2>
        <h2>
          3. Wait for the playlist to appear in your Spotify library <br></br>
        </h2>
        <h2></h2>
      </div>)
  } else {
    return (
    <div>
      <Compare></Compare>
    </div>
    )
  }
}

function SignIn() {
  window.location.href="https://accounts.spotify.com/authorize?response_type=code&client_id=e85923dde9da46258234251d0a5cd5fe&scope=playlist-modify-private%20user-library-read%20playlist-read-private%20playlist-modify-public&redirect_uri=https://www.compareplaylists.com/callback"
}

function toAbout() {
  window.location.href="/about"
}

function ClearSession() {
  Cookies.set('token', null);
  window.location.reload();
}


export default App;
