import queryString from 'query-string'
import Session from './session'
import { Redirect } from 'react-router-dom'
import React from 'react';
import Cookies from 'js-cookie'

function Callback() {
    let token = null;

    let current_url_configuration = 'https://www.compareplaylists.com/callback';


    console.log('making auth request');

    MakeAuthRequest();

    function SaveTokens(response) {
        console.log('saving tokens');
        console.log(response);
        try {
            token = response['access_token'];
            Session.setToken(token);
        } 
        catch(e) {
            //console.error(e);
            console.log(e);
        }
    }
    
    function MakeAuthRequest() {
        let url = window.location.search;
        let params = queryString.parse(url);
    

    
        var details = {
            'grant_type' : 'authorization_code', 'code' : params['code'], 'redirect_uri' : current_url_configuration, 'client_id' : 'e85923dde9da46258234251d0a5cd5fe', 'client_secret' : 'dbfb5c45831541c19fedc1412c007272'
        }
    
        var formBody = [];
        for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        console.log('making request options');
    
        const requestOptions = {
            method: 'POST',
            headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"},
            body: formBody.toString()
        };
    
        console.log(requestOptions);
        console.log('fetching request');
    
        fetch('https://accounts.spotify.com/api/token', requestOptions).then((response) => {return response.json()}).then((responseData) => {SaveTokens(responseData); return responseData;});

    }

    return <Redirect to="/" />;

}

export default Callback;

