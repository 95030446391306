import React, { Component } from 'react';
import './App.css';
import Cookies from 'js-cookie';
import { TextField, RaisedButton, Checkbox, CircularProgress } from 'material-ui';
import { Typography } from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Ripples from 'react-ripples';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

class PlaylistForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      link: '',
      includeFollowing: false,
      show: true,
      displayName: '',
      profileImageURL: '',
      isVerfied: false,
      token: this.props.token,
      loading: false,
      submitted: false,
      playlistLink: '',
    }
    this.checkResponse = this.checkResponse.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.doneLoading = this.doneLoading.bind(this);
    this.badUsername = this.badUsername.bind(this);
    this.responseWrapper = this.responseWrapper.bind(this);
    this.tick = this.tick.bind(this);
  }

  handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

  badUsername() {
    this.setState({loading: false});
    this.setState({submitted: false});
    this.forceUpdate();
  }

  doneLoading() {
    // console.log(resp);
    this.setState({loading: false});
    this.forceUpdate();
  }

  async responseWrapper(response, link, includeSelf) {
    this.setState({loading: true});
    this.forceUpdate();
    var res;
    await this.checkResponse(response, link, includeSelf).then(resp => res = resp).then(console.log(res));
    // console.log(this.state.playlistLink);

    // if(!res.ok) {
    //   console.log("failed");
    //   alert("Something went wrong! Please log out try again.");
    //   this.badUsername();
    // } else {
    //   alert('Your playlist is being created. Check your profile in a few minutes!');
    //   console.log(res);

    //   this.doneLoading(res);

    // }
    console.log(response);
  }

  async checkResponse(response, link, includeSelf) {
    let code = response['status'];
    console.log(code);
    let token_cookie = Cookies.get('token');
    // this.setState({loading: true});
    console.log(this.state.loading);
    this.forceUpdate();
    var res;
    if (code === 401) {
      alert('Your session has expired. Please log out and try again.');
    } else if (code !== 200) {
      alert('A link that you provided was invalid. Please try again.');
      this.setState({loading: false, submitted: false});
    } else {
        const fetchOptions = {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'otheruser': link,
              'token': token_cookie,
              'includeself': includeSelf.toString()
          }
        }
        const response = await axios.post("https://3jrj0fyywi.execute-api.us-west-2.amazonaws.com/staging/compare", fetchOptions);
        const jsonResponse = await response.json();
        console.log(jsonResponse)
//        console.log(this.state.playlistLink);
      return res;
    }
  }

  handleSubmit(event) {
    this.setState({submitted: true, loading: true});
    event.preventDefault();

    var extracted = this.state.link;

    var start_index = extracted.indexOf('/user/');
    if (start_index !== -1) {
      start_index = start_index + '/user/'.length
    } else {
      start_index = extracted.indexOf(':user:');
      if (start_index !== -1) {
        start_index = start_index + ':user:'.length;
      } else {
        start_index = 0;
      }
    }

    var end_index = this.state.link.indexOf('?si=')

    if (end_index !== -1) {
      extracted = extracted.substring(start_index, end_index);
    } else {
      extracted = extracted.substring(start_index);
    }
    console.log(Cookies.get('token'));
    this.setState({link: extracted});
    console.log(this.state.link);

    const usernameOptions = {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + Cookies.get('token') }
    }
    fetch('https://api.spotify.com/v1/users/' + extracted, usernameOptions).then((responseData) => {this.responseWrapper(responseData, this.state.link, this.state.includeFollowing); return responseData;});

  }

  tick() {
    this.setState(state => ({
      seconds: state.seconds + 5
    }));
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 5000);
    this.forceUpdate();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
      if (!this.state.loading && !this.state.submitted) {
        return (
          <MuiThemeProvider theme={theme}>
              <form className={"compare"} onSubmit={this.handleSubmit} >
                  <TextField     
                  value={this.link}
                  onInput={e=>this.setState({link: e.target.value})}
                  required={true}
                  />
                  <Ripples during={200}>
                    <button type="submit" label="Compare" className={"btn btn-primary"} primary={true} >
                      Compare
                    </button>
                  </Ripples>
                  <div className={"checkbox"}>
                  <Checkbox
                      value="true"
                      label={<h6>Include playlists that you follow</h6>}
                      onClick={e=>this.setState({includeFollowing: e.target.value})}
                      class="include"
                      
                  />
                  </div>
              </form>
          </MuiThemeProvider>
        )
      } else if (this.state.loading) {
        console.log("loading");
        return (
//          <div><CircularProgress/></div>
            <h2>Your playlist is being created, check your account in a few minutes!</h2>
        )
      } else {
        return (
          <h5>
            Your playlist is being populated at<span>&nbsp;</span>
            {/* <iframe src={"https://open.spotify.com/embed/playlist/" + this.state.playlistLink} width="80%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe> */}
            <a href={"https://open.spotify.com/playlist/" + this.state.playlistLink} target="_blank">this link</a><br/>Please wait a few minutes for the tool to work!
          </h5>
        )
      }
  }
}

export default PlaylistForm;
