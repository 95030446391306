import App from "./App";
import React from 'react';
import About from './About.js';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Callback from './Callback.js'

const Routes = () => {
    return (
      <div>
          <BrowserRouter>
            <Route exact path="/" component={App} />
            <Route exact path="/about" component={About} />
            <Route path='/callback' component={Callback} />
          </BrowserRouter>
      </div>
    );
};

export default Routes