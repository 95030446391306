import Cookies from 'js-cookie';

var Session = (function() {
    var token = "";
  
    var getToken = function() {
        return Cookies.get('token');    // Or pull this from cookie/localStorage
    };
  
    var setToken = function(t) {
        token = t;    
        Cookies.set('token', t, {});
        window.location = '/';
    };
  
    return {
      getToken: getToken,
      setToken: setToken
    }
  
})();
  
export default Session;