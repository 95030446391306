import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Callback from './Callback.js'
import About from './About.js'
import { Route, BrowserRouter, Link } from 'react-router-dom'
import Title from "react-titles/Title2";
import Cookies from 'js-cookie';
import { TextField, RaisedButton, Checkbox, Paper } from 'material-ui';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Fade, ExpansionPanel, ExpansionPanelDetails, Zoom } from '@material-ui/core';

var token = "";


class UserInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: this.props.token,
      displayName: '',
      profileImageURL: '',
    }
  }

  componentDidMount() {
    const userOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + Cookies.get('token')},
    };

    fetch('https://api.spotify.com/v1/me', userOptions)
    .then((response) => response.json())
    .then(data => {
      try {
        this.setState({ displayName: data.display_name, profileImageURL: data.images[0].url });
      } catch {
        this.setState({ displayName: data.display_name, profileImageURL: require('./genericprofilepic.png')});
      }
      if (this.state.profileImageURL.length === 0) {
        this.setState({profileImageURL: require('./genericprofilepic.png')});
      }
    });
    this.forceUpdate();
  }

  render() {
    return(
        <div>
          <p> <h3>Logged in as </h3> <h2> {this.state.displayName} </h2> </p> <br></br>
          <img
            src={this.state.profileImageURL}
            alt="profilepic"
            class="profileimg"
          />
          <br></br>
        </div>)
  }
}

export default UserInfo;

